<template>
  <div>
    <b-modal ref="thankyou" id="thankyou" v-model="show" centered>
      <div class="thankyou__wrapper">
        <div class="thankyou__wrapper--image">
          <img src="@/assets/images/icons/tick.png" alt="" class="img-fluid" />
        </div>
        <h2>Thank You</h2>
        <h3>{{ title }}</h3>

        <button
          type="button"
          class="btn btn-primary"
          @click.stop.prevent="hideModal('thankyou')"
        >
          Ok
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "InstarsfrontThankyoumodal",
  props: {
    modalShow: Boolean,
    title: String,
  },
  data() {
    return {
      show: this.modalShow,
    };
  },

  methods: {
    hideModal(data) {
      this.$refs[data].hide();
    },
    showModal() {
      this.$refs["thankyou"].show();
    },
  },
  watch: {
    modalShow() {
      this.showModal();
    },
  },
};
</script>

<style lang="scss" scoped>
#thankyou {
  .thankyou__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &--image {
      width: 200px;
      margin-bottom: rem(16px);
    }
    h2 {
      margin-bottom: rem(16px);
    }
    h3 {
      margin-bottom: rem(16px);
    }
  }
}
</style>
